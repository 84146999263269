<template>
  <span>
    <div v-if="editMode" class="field has-addons">
      <div class="control">
        <input class="input is-small" v-model="iValue">
      </div>
      <div class="control">
        <button class="button is-success is-small" @click="$emit('change', { id, value: iValue }); editMode = false">
          <i class="lni lni-checkmark" />
        </button>
      </div>
    </div>
    <span v-else @dblclick="enterEditMode">{{text}}</span>
  </span>
</template>

<script>
export default {
  name: 'TextEdit',
  props: {
    id: Number,
    text: String,
    value: [Number, String]
  },
  data () {
    return {
      iValue: '',
      editMode: false
    }
  },
  methods: {
    enterEditMode () {
      this.iValue = this.text
      this.editMode = true
    }
  }
}
</script>
