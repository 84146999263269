<template>
  <layout page="teacher-report-card">
    <page-title icon="lni lni-library" :breadcrumb="breadcrumb">Boletim</page-title>
    <card-list>
      <card-list-header :title="`Boletim: ${student.name}`" />
      <div class="card-content">
        <div class="form mb-4">
          <error :error="error" />
          <div class="columns is-vcentered">
            <div class="column">
              <div class="field">
                <label for="discipline" class="label">Disciplina</label>
                <div class="select is-fullwidth">
                  <select v-model="idDiscipline" name="discipline" id="discipline">
                    <option :value="0" selected disabled>Selecione a disciplina</option>
                    <option
                      v-for="(discipline) in disciplines"
                      :key="discipline.id"
                      :value="discipline.id"
                    >
                      {{discipline.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label for="score" class="label">Nota</label>
                <div class="control">
                  <input v-model="score" class="input" type="number" name="score" id="score">
                </div>
              </div>
            </div>
            <div class="column is-one-fifth">
              <div class="field">
                <label style="visibility: hidden" for="" class="label">Adicionar</label>
                <button class="button is-primary" @click="addEntry">Adicionar</button>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div
            v-for="discipline in disciplines"
            :key="discipline.id"
            class="column"
          >
            <strong>{{ discipline.name }}</strong>
          </div>
        </div>
        <div class="columns">
          <div
            v-for="discipline in disciplines"
            :key="discipline.id"
            class="column"
          >
            <div
              v-for="(entry) in disciplineReports(discipline.id)"
              :key="entry.id"
              class=""
            >
              <text-edit :id="entry.id" :text="entry.score" @change="saveEdit" />
              <i class="lni lni-close has-text-danger entry-delete ml-2" @click="remove(entry.id)" />
            </div>
          </div>
        </div>
        <p>Dois cliques para editar a nota</p>
      </div>
    </card-list>
  </layout>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api'
import SwalDelete from '@/helpers/SwalDelete'
import { DASHBOARD, CLASS_LIST, CLASS, REPORT_CARD } from '@/config/breadcrumb/teacher'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import CardList from '@/components/CardList'
import CardListHeader from '@/components/CardList/Header'
import Error from '@/components/ui/Error'
import TextEdit from '@/components/ui/TextEdit'

export default {
  name: 'ReportCard',
  components: {
    Layout,
    PageTitle,
    CardList,
    CardListHeader,
    Error,
    TextEdit
  },
  created () {
    this.idClass = this.$route.params.idClass
    this.idStudent = this.$route.params.idStudent

    api.get(`/report-cards/classes/${this.idClass}/students/${this.idStudent}`).then(res => {
      if (res.status === 404) {
        this.$router.push('/p/t')
      }

      this.reportCard = res.data
    })

    api.get(`/disciplines/teachers/${this.user.id}/classes/${this.idClass}`).then(res => {
      this.disciplines = res.data
    })

    api.get(`/students/${this.idStudent}`).then(res => {
      if (this.status !== 404) {
        this.student = res.data
      }
    })
  },
  data () {
    return {
      idClass: 0,
      idStudent: 0,
      student: {},
      disciplines: {},
      idDiscipline: 0,
      score: '',
      reportCard: {},
      idEntry: 0,
      error: null,
      editMode: false
    }
  },
  computed: {
    ...mapState(['user']),
    breadcrumb () {
      return [
        DASHBOARD,
        CLASS_LIST,
        CLASS(this.idClass),
        REPORT_CARD(this.idClass, this.idStudent, true)
      ]
    }
  },
  methods: {
    disciplineReports (idDiscipline) {
      if (this.reportCard.entries === undefined) return
      return this.reportCard.entries.filter(entry => entry.discipline.id === idDiscipline)
    },

    addEntry () {
      if (this.idDiscipline === '') {
        this.error = 'Selecione a disciplina'
        return
      }
      this.error = null

      if (this.score === '') {
        this.error = 'Adicione a nota'
        return
      }
      this.error = null

      api.post(`/report-cards/${this.reportCard.id}/entries`, {
        idDiscipline: this.idDiscipline,
        score: this.score
      }).then(res => {
        if (res.status === 201) {
          this.reportCard.entries.push(res.data)
          this.idDiscipline = 0
          this.score = ''
          this.error = null
        } else {
          this.error = 'Não foi possivel adicionar a nota'
        }
      }).catch(() => {
        this.error = 'Não foi possivel adicionar a nota'
      })
    },
    remove (idEntry) {
      SwalDelete.fire().then(result => {
        if (result.isConfirmed) {
          api.delete(`/report-cards/${this.reportCard.id}/entries/${idEntry}`).then(res => {
            if (res.status === 200) {
              this.reportCard.entries = this.reportCard.entries.filter(entry => entry.id !== idEntry)
            }
          })
        }
      })
    },
    saveEdit ({ id, value }) {
      api.patch(`/report-cards/${this.reportCard.id}/entries/${id}`, {
        score: value
      }).then(res => {
        if (res.status === 200) {
          this.reportCard.entries = this.reportCard.entries.map(entry => {
            if (entry.id === id) {
              return { ...entry, score: value }
            }

            return entry
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.entry-delete {
  cursor: pointer;
}
</style>
